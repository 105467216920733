import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  doc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, auth } from "../../services/firebase";
import InventoryDataService from "../../services/inventories.service";

import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { TextField } from "formik-mui";
import { array, number, object, string } from "yup";
import { CustomizedSelect } from "../../common/select";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import { enqueueSnackbar } from "notistack";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

export const SurveyTruck = () => {
  const [truckId, setTruck] = useState("");
  const [compartments, setCompartments] = useState();
  const navigate = useNavigate();

  const [trucks, loading, error] = useCollectionOnce(
    query(
      collection(db, "trucks"),
      where("active", "==", true),
      orderBy("truckNumber")
    )
  );

  useEffect(() =>{
    const fetchData = async () =>{
      if (truckId !== ""){
        const q = query(
          collection(db,"trucks",truckId,"compartments")
        );
        const qs = await getDocs(q);
        const c = [];

        qs.forEach((doc) => {
          c[doc.id] = doc.data()
        });

        setCompartments(c);
      }
    };

    fetchData();
  }, [truckId]);

  const selectTruck = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setTruck(event.target.value);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Truck Reporting</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/survey")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <CircularProgress></CircularProgress>}
      {trucks && (
        <FormControl fullWidth>
          <InputLabel
            id="truck-select-label"
            style={{ backgroundColor: "white" }}
          >
            Select Truck
          </InputLabel>
          <Select
            labelId="truck-select-label"
            id="truck-select"
            label="Location"
            value={truckId}
            onChange={(event) => {
              selectTruck(event);
            }}
          >
            {trucks.docs.map((doc) => (
              <MenuItem
                key={doc.id}
                value={doc.id}
                name={doc.data().truckDescription}
              >
                {doc.data().truckDescription}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {truckId && <SurveyTruckForm truckId={truckId} trucks={trucks} compartments={compartments}/>}
    </Stack>
  );
};

const SurveyTruckForm = (props) => {
  const [reportingDate, setReportingDate] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();
  const [products, loading, error] = useCollectionOnce(
    query(
      collection(db, "products"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  const [locations, loadingLocations, errorLocations] = useCollectionOnce(
    query(
      collection(db, "locations"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  useEffect(() => {
    if (formikRef.current) {
      const truck = props.trucks.docs.find((t) => t.id === props.truckId);
      
      if (truck && truck.data().latestLocation){
        formikRef.current.setFieldValue("location", truck.data().latestLocation);
      }

      formikRef.current.setFieldValue("compartments", [
        {
          compartment: 1,
          product: (props.compartments && props.compartments['1'] && props.compartments['1'].latestProduct) ? props.compartments['1'].latestProduct : "",
          gallons: (props.compartments && props.compartments['1'] && props.compartments['1'].latestGallons) ? props.compartments['1'].latestGallons : 0,
        },
        {
          compartment: 2,
          product: (props.compartments && props.compartments['2'] && props.compartments['2'].latestProduct) ? props.compartments['2'].latestProduct : "",
          gallons: (props.compartments && props.compartments['2'] && props.compartments['2'].latestGallons) ? props.compartments['2'].latestGallons : 0,
        },
        {
          compartment: 3,
          product: (props.compartments && props.compartments['3'] && props.compartments['3'].latestProduct) ? props.compartments['3'].latestProduct : "",
          gallons: (props.compartments && props.compartments['3'] && props.compartments['3'].latestGallons) ? props.compartments['3'].latestGallons : 0,
        },
        {
          compartment: 4,
          product: (props.compartments && props.compartments['4'] && props.compartments['4'].latestProduct) ? props.compartments['4'].latestProduct : "",
          gallons: (props.compartments && props.compartments['4'] && props.compartments['4'].latestGallons) ? props.compartments['4'].latestGallons : 0,
        },
        {
          compartment: 5,
          product: (props.compartments && props.compartments['5'] && props.compartments['5'].latestProduct) ? props.compartments['5'].latestProduct : "",
          gallons: (props.compartments && props.compartments['5'] && props.compartments['5'].latestGallons) ? props.compartments['5'].latestGallons : 0,
        },
      ]);
    }
  }, [props.compartments, props.truckId, props.trucks]);

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={object({
        location: string().required("Please select a location"),
        compartments: array(
          object({
            product: string(),
            gallons: number().integer("Please enter gallons in whole numbers"),
          })
        )
          .test({
            name: "gallons-for-product",
            message: "You must have greater than zero gallons for a product",
            test: (val) =>
              val.every(({ product, gallons }, index) => {
                if (product !== undefined && gallons <= 0) {
                  //console.log(index, product, gallons);
                  return false;
                }

                return true;
              }),
          })
          .test({
            name: "product-for-gallons",
            message: "You must have a product when entering gallons",
            test: (val) =>
              val.every(({ product, gallons }, index) => {
                if (product === undefined && gallons > 0) {
                  //console.log(index, product, gallons);
                  return false;
                }

                return true;
              }),
          }),
      })}
      initialValues={{
        location: "",
        compartments: [
          {
            compartment: 1,
            product: "",
            gallons: 0,
          },
          {
            compartment: 2,
            product: "",
            gallons: 0,
          },
          {
            compartment: 3,
            product: "",
            gallons: 0,
          },
          {
            compartment: 4,
            product: "",
            gallons: 0,
          },
          {
            compartment: 5,
            product: "",
            gallons: 0,
          },
        ],
      }}
      onSubmit={async (values) => {
        const entryDate = reportingDate.startOf("day").toDate();
        const lastUpdated = serverTimestamp();

        try {
          const truck = props.trucks.docs.find((t) => t.id === props.truckId);

          for (const compartment of values.compartments) {
            if (compartment.gallons > 0) {
              const data = {
                reportingDate: entryDate,
                locationId: values.location,
                truckId: props.truckId,
                compartment: compartment.compartment,
                productId: compartment.product,
                gallons: compartment.gallons,
                lastUpdatedBy: auth.currentUser.uid,
              };

              await InventoryDataService.create(data);
            }

            const docRef = doc(
              db,
              "trucks",
              props.truckId,
              "compartments",
              compartment.compartment.toString()
            );
            const docCompartment = await getDoc(docRef);
            console.log('docCompartment', docCompartment);

            if (
              !docCompartment.exists() ||
              !docCompartment.data().lastReading ||
              docCompartment.data().lastReading <= Timestamp.fromDate(entryDate)
            ) {
              const compartmentInventory = {
                lastReading: Timestamp.fromDate(entryDate),
                latestProduct: compartment.product,
                latestGallons: compartment.gallons,
              };

              await setDoc(docRef, compartmentInventory, { merge: true });
            }
          }

          if (
            !truck.data().lastReading ||
            truck.data().lastReading <= Timestamp.fromDate(entryDate)
          ) {
            await updateDoc(doc(db, "trucks", truck.id), {
              lastReading: Timestamp.fromDate(entryDate),
              latestLocation: values.location,
            });
          }

          enqueueSnackbar("Truck Inventory Recorded");
          navigate("/survey");
        } catch (error) {
          console.log('error', error);
          enqueueSnackbar(error);
        }
      }}
    >
      {({ values, errors, isSubmitting }) => (
        <Form>
          <Stack spacing={0.5}>
            <Typography variant="h5" textAlign={"center"}>
              Enter Truck Inventory Information
            </Typography>
            {errorLocations && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(errorLocations)}</strong>
              </Grid>
            )}
            {loadingLocations && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    Where did you load your truck?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`location`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box display="flex" justifyContent="right">
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="cehm-error"
                    ></ErrorMessage>
                  </Box>
                </Grid>
              </Grid>
            )}
            <FieldArray name="compartments">
              {() => (
                <>
                  {values.compartments.map((comp, index) => (
                    <Grid container key={index} spacing={1}>
                      {error && (
                        <Grid item xs={12}>
                          <strong>Error: {JSON.stringify(error)}</strong>
                        </Grid>
                      )}
                      {loading && (
                        <Grid item xs={12}>
                          <Skeleton />
                        </Grid>
                      )}
                      {products && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="h6">
                              Compartment {comp.compartment}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm>
                            <Field
                              fullWidth
                              component={CustomizedSelect}
                              products={products}
                              label="Select Product"
                              name={`compartments[${index}].product`}
                            >
                              <MenuItem value="" name="No Product" selected>
                                No Product
                              </MenuItem>
                              {products.docs.map((doc) => (
                                <MenuItem
                                  key={doc.id}
                                  value={doc.id}
                                  name={doc.data().name}
                                >
                                  {doc.data().name}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                          <Grid item xs={6} sm>
                            <Field
                              fullWidth
                              name={`compartments[${index}].gallons`}
                              component={TextField}
                              label="Gallons"
                              type="number"
                              variant="outlined"
                            ></Field>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                </>
              )}
            </FieldArray>
            <Box display="flex" justifyContent="right">
              <ErrorMessage
                name="compartments"
                component="div"
                className="cehm-error"
              ></ErrorMessage>
            </Box>
            <Box display="flex" justifyContent="right" p={2}>
              <Box display="flex" pr={2}>
                <DatePicker
                  name="reportingDate"
                  label="Reporting Date"
                  value={reportingDate}
                  onChange={(newValue) => setReportingDate(newValue)}
                ></DatePicker>
              </Box>
              <Box display="flex">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                >
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
